export const PROJECT_STATUSES = {
  1: 'Setup',
  2: 'Active',
  3: 'Archived',
  'Setup': 1,
  'Active': 2,
  'Archived': 3,
};

export const PROJECT_QUALIFICATION_STATUSES = {
  1: 'Pending',
  2: 'Qualified',
  3: 'Not qualified',
  'Pending': 1,
  'Qualified': 2,
  'Not qualified': 3,
};

export const SIMPLE_SELECT_OPTIONS = [
  {
    text: 'Yes',
    value: true
  },
  {
    text: 'No',
    value: false,
  }
]

export const ANSWER_DESCRIPTION_PLACEHOLDER = 'Enter a description';

export const PROJECT_INITIAL_STATE = {
  list: [],
  selected: {
    name: '',
    status: '',
    project_types: [],
    qualification_status_id: '',
    description: '',
    project_start_date: null,
    project_end_date: null,
    discovery_description: ''
  },
  softwareTypes: [],
  businessComponentTypes: []
};

export const MAX_TABLE_HEIGHT = '90%';

export const PROJECT_QUALIFICATION_MAX_CHARACTERS = 32767;
