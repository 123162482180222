import { TOGGLE_CRUD_PANEL, GET_JURISDICTIONS_SUCCESS, GET_HOUR_SOURCES, FETCH_PRINCIPLE_BUSINESS_ACTIVITY_CODES_SUCCESS } from '../constants/actionTypes/common';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';

export const toggleCrudPanel = (isCrudPanelOpen) => ({
  type: TOGGLE_CRUD_PANEL,
  isCrudPanelOpen,
});

export const getJurisdictions = () => async dispatch => {
  try {
    const jurisdictions = await apiClient({
      method: 'get',
      url: '/jurisdiction',
    });
    return dispatch(getJurisdictionsSuccess(jurisdictions));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const getJurisdictionsSuccess = (jurisdictions) => ({
  type: GET_JURISDICTIONS_SUCCESS,
  jurisdictions,
});

export const fetchPrincipleBusinessActivityCodes = (jurisdictionId, taxYear) => async dispatch => {
  if (jurisdictionId === 235 && taxYear >= 2024) {
    try {
      const principleBusinessActivityCodes = await apiClient({
        method: 'get',
        url: `/principle-business-activity/type?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}`
      });
      return dispatch(fetchPrincipleBusinessActivityCodesSuccess(principleBusinessActivityCodes));
    } catch (err) {
      dispatch(handleError(err));
    }
  }
};

const fetchPrincipleBusinessActivityCodesSuccess = (principleBusinessActivityCodes) => ({
  type: FETCH_PRINCIPLE_BUSINESS_ACTIVITY_CODES_SUCCESS,
  principleBusinessActivityCodes,
});


export const setHourSources = () => async dispatch => {
  try {
    const hourSources = await apiClient({
      method: 'get',
      url: `/expense/hour-sources`,
    });
    dispatch(getHourSourcesSuccess(hourSources));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const getHourSourcesSuccess = hourSources => ({
  type: GET_HOUR_SOURCES,
  hourSources
});
