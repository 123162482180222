import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import getValueFromEvent from '../../../../../utils/getValueFromEvent';
// ACTIONS
import {
  deleteExistingEntity,
  selectEntityForCrud,
  updateSelectedEntity,
  createNewEntity,
  getCurrencies as getCurrenciesAction,
  editExistingEntity
} from '../../../../../actions/entities';

// SELECTORS
import { getSelectedEntityForCrud, getCurrencies, getEntities } from '../../../../../selectors/entities';
import { getSelectedContainer } from '../../../../../selectors/containers';
import { getEntityGroups } from '../../../../../selectors/entityGroups';
import { getJurisdictions } from '../../../../../selectors/common';
import { fetchPrincipleBusinessActivityCodes } from '../../../../../actions/common';
// CONSTANTS
import { CRUD_TYPES, CRUD_INPUT_TYPES, NECESSARY_ROLES_PER_COMPONENT } from '../../constants'

// UTILS
import dateToMysqlFormat from '../../../../../utils/dateToMysqlFormat';
import dateToDisplayableFormat from '../../../../../utils/dateToDisplayableFormat';
import moment from 'moment';
import FormBuilder from '../../../../../commonComponents/FormBuilder';
import { buildEntityInputFields } from './buildEntityInputFields';
import useRoleRestriction from "../../../../../hooks/useRoleRestriction";
import { getPrincipleBusinessActivityCodes } from '../../../../../selectors/common';
const EntityCrud = (props) => {
  const dispatch = useDispatch();
  const entityGroups = useSelector(getEntityGroups, shallowEqual);
  const entity = useSelector(getSelectedEntityForCrud);
  const jurisdictions = useSelector(getJurisdictions, shallowEqual);
  const countryJurisdictions = jurisdictions.filter((jurisdiction => jurisdiction.type === 'country'));
  const principleBusinessActivityCodes = useSelector(getPrincipleBusinessActivityCodes, shallowEqual);
  const currencies = useSelector(getCurrencies, shallowEqual);
  const entities = useSelector(getEntities, shallowEqual);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const selectedContainerId = selectedContainer.container_id;
  const history = useHistory();
  const now = new Date();
  const flags = useFlags();
  const sectionGForm6765 = flags.sectionGForm6765;
  const defaultFiscalYearStart = selectedContainer.tax_year ? moment().year(selectedContainer.tax_year).utc().startOf('year') : moment(now).utc().startOf('year');
  const defaultFiscalYearEnd = selectedContainer.tax_year ? moment().year(selectedContainer.tax_year).utc().endOf('year') : moment(now).utc().endOf('year');
  const fiscalYearStart = entity.fiscal_year_start ? entity.fiscal_year_start : defaultFiscalYearStart;
  const fiscalYearEnd = entity.fiscal_year_end ? entity.fiscal_year_end : defaultFiscalYearEnd;

  const yearStartMysqlFormatted = dateToMysqlFormat(fiscalYearStart);
  const yearEndMysqlFormatted = dateToMysqlFormat(fiscalYearEnd);
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);

  useEffect(() => {
    if (sectionGForm6765) {
      dispatch(fetchPrincipleBusinessActivityCodes(entity.jurisdiction.jurisdiction_id, selectedContainer.tax_year));
    }
  }, [dispatch, entity.jurisdiction, selectedContainer.tax_year, sectionGForm6765]);

  useEffect(() => {
    if (props.crudType !== CRUD_TYPES.create) {
      dispatch(selectEntityForCrud(props))
    }
    dispatch(getCurrenciesAction())
  }, [dispatch, props]);

  useEffect(() => {
    if (props.crudType === CRUD_TYPES.create) {
      return;
    }

    if (entity.fiscal_year_start) {
      const fiscal_year_start = dateToDisplayableFormat(entity.fiscal_year_start);
      dispatch(updateSelectedEntity('fiscal_year_start', fiscal_year_start));
    }
    if (entity.fiscal_year_end) {
      const fiscal_year_end = dateToDisplayableFormat(entity.fiscal_year_end);
      dispatch(updateSelectedEntity('fiscal_year_end', fiscal_year_end));
    }
  }, [dispatch, entity.fiscal_year_end, entity.fiscal_year_start, props.crudType]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === CRUD_INPUT_TYPES.jurisdiction.name) {
      const [inputValue] = countryJurisdictions.filter(({ jurisdiction_id }) => jurisdiction_id === value)
      dispatch(updateSelectedEntity(name, inputValue))
    }
    else {
      const inputValue = getValueFromEvent(e);
      dispatch(updateSelectedEntity(name, inputValue))
    }
  }

  const handleJurisdictionChange = (e, name) => {
    const { value } = e.target;
    const [inputValue] = countryJurisdictions.filter(({ jurisdiction_id }) => jurisdiction_id === parseInt(value));
    const currencyId = inputValue.default_currency;
    dispatch(updateSelectedEntity(name, inputValue))
    dispatch(updateSelectedEntity('currency_id', currencyId))
  }

  const handlePrincipleBusinessActivityChange = (e, name) => {
    const { value } = e.target;
    dispatch(updateSelectedEntity(name, value))
  }

  const handleParentEntityChange = e => {
    const { value, name: fullName } = e.target;
    const [name, index] = fullName.split('-')
    let { parentEntities } = entity;
    if (parentEntities) parentEntities[index] = { ...parentEntities[index], parent_entity_id: value };
    else { parentEntities = [{ parent_entity_id: value }] }
    dispatch(updateSelectedEntity(name, parentEntities))
  }

  const handleParentEntityPercentageChange = (event, parentEntityToUpdate) => {
    const { value } = event.target;
    const [name, index] = parentEntityToUpdate.split('-');
    const { parentEntities } = entity;
    parentEntities[index] = { ...parentEntities[index], ownership: value };
    dispatch(updateSelectedEntity(name, parentEntities))
  }

  const handleCreateEntity = () => {
    let newEntity = {
      name: entity.name,
      code: entity.code,
      currencyId: entity.currency_id,
      taxJurisdictionId: entity.jurisdiction.jurisdiction_id,
      containerId: selectedContainerId,
      fiscalYearEnd: yearEndMysqlFormatted,
      fiscalYearStart: yearStartMysqlFormatted,
      description: entity.description || null,
      industry: entity.industry || null,
      entityGroupId: entity.entity_group_id || null,
      entityType: entity.entity_type || null,
      yearOfIncorporation: Number(entity.year_of_incorporation),
      ein: entity.ein,
      principleBusinessActivityCode: entity.principle_business_activity_code ? Number(entity.principle_business_activity_code) : null
    }
    if (entity.parentEntities.length) {
      const parents = entity.parentEntities.map(({ ownership, parent_entity_id }) => ({ ownership: Number(ownership), parentEntityId: parent_entity_id }));
      newEntity = { ...newEntity, parents }
    }
    dispatch(createNewEntity(newEntity))
    props.closeCrudPanel()
  }

  const handleEditEntity = () => {
    let parents = {}
    const { parentEntities, originalParentEntities } = entity;
    let newEntity = {
      entityId: entity.entity_id,
      name: entity.name,
      code: entity.code,
      currencyId: entity.currency_id,
      taxJurisdictionId: entity.jurisdiction.jurisdiction_id,
      containerId: selectedContainerId,
      fiscalYearEnd: dateToMysqlFormat(entity.fiscal_year_end),
      fiscalYearStart: dateToMysqlFormat(entity.fiscal_year_start),
      description: entity.description || null,
      industry: entity.industry || null,
      entityGroupId: entity.entity_group_id || null,
      entityType: entity.entity_type || null,
      yearOfIncorporation: Number(entity.year_of_incorporation),
      ein: entity.ein,
      principleBusinessActivityCode: entity.principle_business_activity_code ? Number(entity.principle_business_activity_code) : null
    }
    if (parentEntities && parentEntities.length) {
      parents = { ...parents, add: parentEntities.map(({ ownership, parent_entity_id }) => ({ ownership: Number(ownership), parentEntityId: parent_entity_id })) }
    };
    if (originalParentEntities && originalParentEntities.length) {
      parents = { ...parents, remove: originalParentEntities.map(({ parent_entity_id }) => (parent_entity_id)) }
    }
    dispatch(editExistingEntity({ ...newEntity, parents }))
    props.closeCrudPanel()
  }

  const handleDeleteEntity = () => {
    const { entity_id } = entity;
    dispatch(deleteExistingEntity(entity_id, selectedContainerId))
    props.closeCrudPanel()
  }

  const handleViewEntityInfoCLick = () => {
    const entityInfoRedirectUrl = `/service-manager/entities/${entity.entity_id}/entity-info`;
    history.push(entityInfoRedirectUrl);
  };

  const getAvailableEntities = (siblingIndex) => {
    const { parentEntities } = entity;
    if (parentEntities && parentEntities[siblingIndex]) {
      return entities.filter(({ entity_id }) => entity_id !== parentEntities[siblingIndex].parent_entity_id)
    }
    return entities;
  };

  const resolveCurrencyName = () => {
    const [currency] = currencies.filter(currency => currency.currency_id === entity.jurisdiction.default_currency);
    const name = currency ? currency.name : '';
    return name
  }
  const shouldDisplayEntityChartTab = hasRoleAccessTo.EntityChartTab && props.crudType === 'edit';

  const formActions = [
    {
      name: 'View entity info',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleViewEntityInfoCLick,
    },
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleCreateEntity,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleEditEntity,
      color: 'secondary',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: shouldDisplayEntityChartTab,
      onClick: handleDeleteEntity,
      color: 'secondary',
      shouldAskConfirmation: true
    },
  ];

  const inputFields = buildEntityInputFields(entity, {
    isEINRequired: (selectedContainer.tax_year >= 2024 && sectionGForm6765),
    entityGroups,
    countryJurisdictions,
    principleBusinessActivityCodes,
    currencyName: resolveCurrencyName(),
    fiscalYearStart,
    fiscalYearEnd,
    containerTaxYear: selectedContainer.tax_year,
    firstParentAvailableEntities: getAvailableEntities(1),
    secondParentAvailableEntities: getAvailableEntities(0),
    sectionGForm6765,
    handleParentEntityChange,
    handleParentEntityPercentageChange,
    handleJurisdictionChange,
    handlePrincipleBusinessActivityChange
  });
  return (
    <div>
      <FormBuilder
        onChange={handleInputChange}
        fields={inputFields}
        formActions={formActions}
      />
    </div>
  )
}

EntityCrud.propTypes = {
  crudType: PropTypes.string,
  closeCrudPanel: PropTypes.func,
}

export default EntityCrud
