import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  createProject,
  deleteProject,
  editProject,
  selectProjectCrud,
  setSelectedProjectField,
  fetchSoftwareTypes,
  fetchProjectBusinessComponentTypes,
} from '../../../../actions/project';
import { PROJECT_INITIAL_STATE, PROJECT_QUALIFICATION_STATUSES, PROJECT_STATUSES } from '../constants';
import { makeStyles } from '@material-ui/core';
import dateToMysqlFormat from '../../../../utils/dateToMysqlFormat';
import dateToDisplayableFormat from '../../../../utils/dateToDisplayableFormat';
import { buildProjectInputFields } from './buildProjectInputFields';
import FormBuilder from '../../../../commonComponents/FormBuilder';
import { setSelectedProjectType } from '../../../../actions/project';
import { getSelectedContainer } from '../../../../selectors/containers';
import getValueFromEvent from '../../../../utils/getValueFromEvent';

const useStyles = makeStyles((theme) => ({
  qualificationStatus: {
    padding: `${theme.spacing(1)}px 0 0 0`,
    fontSize: '1rem',
  },
  qualifiedProjectText: {
    color: theme.palette.green
  },
  notQualifiedProjectText: {
    color: theme.palette.pink
  },
  pendingProjectText: {
    color: theme.palette.black[800]
  }
}));

const ProjectCrud = (props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();
  const project = useSelector(state => state.projects.selected);
  const containerId = useSelector(state => state.common.selectedContainer);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const entityId = useSelector(state => state.common.selectedEntity);
  const entities = useSelector(state => state.entities.list);
  const selectedEntity = entities?.find(entity => entity.entity_id === entityId);
  const tenantId = useSelector(state => state.common.selectedTenant);
  const softwareTypes = useSelector(state => state.projects.softwareTypes);
  const flags = useFlags();
  const sectionGForm6765 = flags.sectionGForm6765;
  const discoveryDescription = useSelector(state => state.projects.selected.discovery_description);
  const qualificationStatusId = project.qualification_status_id;
  const businessComponentTypes = useSelector(state => state.projects.businessComponentTypes);
  const { allProjectTypes } = props;

  useEffect(() => {
    dispatch(fetchSoftwareTypes(selectedEntity?.jurisdiction?.jurisdiction_id, selectedContainer.tax_year));
    dispatch(fetchProjectBusinessComponentTypes(selectedEntity?.jurisdiction?.jurisdiction_id, selectedContainer.tax_year));
  }, [dispatch, selectedEntity?.jurisdiction?.jurisdiction_id, selectedContainer.tax_year]);

  useEffect(() => {
    if (props.crudType === 'create') {
      return;
    }

    if (project.project_start_date) {
      const projectStartDate = dateToDisplayableFormat(project.project_start_date);
      dispatch(setSelectedProjectField('project_start_date', projectStartDate));
    }
    if (project.project_end_date) {
      const projectEndDate = dateToDisplayableFormat(project.project_end_date);
      dispatch(setSelectedProjectField('project_end_date', projectEndDate));
    }
  }, [dispatch, project.project_end_date, project.project_start_date, props.crudType]);

  useEffect(() => {
    if (props.crudType !== 'create') {
      dispatch(selectProjectCrud(props));
    } else {
      dispatch(selectProjectCrud(PROJECT_INITIAL_STATE.selected));
    }
  }, [dispatch, props]);

  const handleChange = (event) => {
    const { name } = event.target;
    const value = getValueFromEvent(event);
    dispatch(setSelectedProjectField(name, value));
  };

  const handleProjectUpdate = () => {
    const { project_id } = project;
    const body = makeProjectBody();
    dispatch(editProject(project_id, body, selectedContainer.tax_year));
    props.closeCrudPanel();
  };

  const handleProjectDelete = () => {
    dispatch(deleteProject(project.project_id, project.entity_id, selectedContainer.tax_year));
    props.closeCrudPanel();
  };

  const handleProjectCreate = () => {
    const body = makeProjectBody();
    dispatch(createProject(body, selectedContainer.tax_year));
    props.closeCrudPanel();
  };

  const redirectToQualifyPage = () => {
    history.push('/service-manager/projects/qualify', {
      project: { name: project.name, id: project.project_id }
    })
  };


  const handleProjectTypeChange = (selectedProjectTypes) => {
    dispatch(setSelectedProjectType('project_types', selectedProjectTypes))
  };

  const getSelectedProjectTypes = () => {
    return allProjectTypes.filter((projectType) => (
      project.project_types.find((projectTypeOfProject) => projectType.id === projectTypeOfProject.id)
    ));
  };

  const makeProjectBody = () => {
    const { name, status, qualification_status_id, description, project_types } = project;
    const defaultQualificationStatusId = PROJECT_QUALIFICATION_STATUSES['Pending'];
    const qualificationStatusId = qualification_status_id || defaultQualificationStatusId;

    const body = {
      containerId,
      entityId,
      tenantId,
      projectTypeIds: project_types.map(projectType => projectType.id),
      name,
      description: description || null,
      qualificationStatusId: qualificationStatusId,
      status: PROJECT_STATUSES[status],
      projectStartDate: dateToMysqlFormat(project.project_start_date),
      projectEndDate: dateToMysqlFormat(project.project_end_date),
      softwareTypeId: project.software_type_id,
      discoveryDescription: discoveryDescription || null,
      businessComponentTypeId: project.business_component_type_id,
    };

    return body;
  };

  const getQualificationStatusClassName = (status) => {
    const qualified = PROJECT_QUALIFICATION_STATUSES['Qualified'];
    const notQualified = PROJECT_QUALIFICATION_STATUSES['Not qualified'];

    const statuses = {
      [qualified]: styles.qualifiedProjectText,
      [notQualified]: styles.notQualifiedProjectText,
    };

    return statuses[status] || styles.pendingProjectText;
  };

  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleProjectCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleProjectUpdate,
      color: 'secondary',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleProjectDelete,
      color: 'secondary',
      shouldAskConfirmation: true
    },
    {
      name: 'Qualify Project',
      shouldDisplay: props.crudType === 'edit',
      onClick: redirectToQualifyPage,
    },

  ];

  const handleBusinessComponentTypeChange = (e) => {
    const value = getValueFromEvent(e);
    dispatch(setSelectedProjectField('business_component_type_id', value));
    if (value !== 1) {
      dispatch(setSelectedProjectField('software_type_id', null));
    }
  };

  const fieldInputs = buildProjectInputFields(project, {
    containerTaxYear: selectedContainer.tax_year,
    businessComponentTypes,
    jurisdictionId: selectedEntity?.jurisdiction?.jurisdiction_id,
    sectionGForm6765,
    allProjectTypes,
    softwareTypes,
    discoveryDescription,
    handleProjectTypeChange,
    selectedProjectTypes: getSelectedProjectTypes(),
    handleBusinessComponentTypeChange
  });
  return (
    <div>
      <FormBuilder
        onChange={handleChange}
        fields={fieldInputs}
        formActions={formActions}
      >
        {props.crudType !== 'create' && (
          <p className={styles.qualificationStatus}>
            Qualification Status: <br />
            <span className={getQualificationStatusClassName(qualificationStatusId)}>
              <b>{PROJECT_QUALIFICATION_STATUSES[qualificationStatusId]}</b>
            </span>
          </p>
        )}
      </FormBuilder>
    </div>
  );
};

export default ProjectCrud;
