import { checkIsDateValid } from '../../../../customValidations/checkIsDateValid';

const businessComponentTypeComputerSoftware = 1;

const STATUS_OPTIONS = [
  {
    name: 'Setup',
    value: 'Setup',
  },
  {
    name: 'Active',
    value: 'Active',
  },
  {
    name: 'Archived',
    value: 'Archived',
  },
];

export const buildProjectInputFields = (project, inputData) => [
  {
    name: 'name',
    label: 'Name',
    type: 'Input',
    value: project.name,
    required: true,
  },
  {
    name: 'description',
    label: 'Description',
    type: 'Input',
    value: project.description,
    muiProps: {
      multiline: true,
      rows: 3,
    },
  },
  {
    name: 'discovery_description',
    label: 'Discovery Description',
    type: 'Input',
    value: project.discovery_description,
    muiProps: {
      multiline: true,
      rows: 3,
    },
    shouldDisplay: inputData && inputData.jurisdictionId === 235 && inputData.containerTaxYear >= 2024 && inputData.sectionGForm6765,
  },
  {
    name: 'status',
    label: 'Status',
    type: 'Selector',
    options: STATUS_OPTIONS,
    value: project.status,
    optionKeys: {
      name: 'name',
      value: 'value',
    },
    required: true,
  },
  {
    name: 'business_component_type_id',
    label: 'Business Component Type',
    type: 'Selector',
    options: inputData.businessComponentTypes,
    value: project.business_component_type_id,
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    required: true,
    shouldDisplay: inputData && inputData.jurisdictionId === 235 && inputData.containerTaxYear >= 2024 && inputData.sectionGForm6765,
    onChange: inputData.handleBusinessComponentTypeChange
  },
  {
    name: 'software_type_id',
    label: 'Software Type',
    type: 'Selector',
    options: inputData.softwareTypes,
    value: project.business_component_type_id === businessComponentTypeComputerSoftware ? project.software_type_id : null,
    optionKeys: { name: 'name', value: 'id' },
    required: project.business_component_type_id === businessComponentTypeComputerSoftware,
    muiProps: {
      disabled: project.business_component_type_id !== businessComponentTypeComputerSoftware
    },
    shouldDisplay: inputData && inputData.jurisdictionId === 235 && inputData.containerTaxYear >= 2024 && inputData.sectionGForm6765
  },
  {
    label: 'Project Types',
    type: 'MultipleSelect',
    options: inputData.allProjectTypes,
    selectedOptions: inputData.selectedProjectTypes,
    optionKeys: { name: 'name', value: 'id' },
    onChange: inputData.handleProjectTypeChange,
    maxChips: 2
  },
  {
    name: 'project_start_date',
    label: 'Project Start Date',
    type: 'Date',
    value: project.project_start_date,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
  {
    name: 'project_end_date',
    label: 'Project End Date',
    type: 'Date',
    value: project.project_end_date,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
];
