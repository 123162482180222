const EMAIL_VALIDATION_REGEX =  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const LOGIN_REDIRECT = '/launch-pad';
const EULA_REDIRECT = '/eula';
const PRODUCT_NAME = 'R&D Tax Credit';
const LAUNCH_PAD_TITLE = `${PRODUCT_NAME} Launch Pad`;
const ENVIRONMENT_SHORT_MAP = {
    'develop': 'dev',
    'staging': 'stag',
    'production': 'prod'
};
const PDF_MIME_TYPE_HEX = '25504446';
const XLSX_MIME_TYPE_HEX = '504B34';

export {
    EMAIL_VALIDATION_REGEX,
    LOGIN_REDIRECT,
    EULA_REDIRECT,
    PRODUCT_NAME,
    LAUNCH_PAD_TITLE,
    ENVIRONMENT_SHORT_MAP,
    PDF_MIME_TYPE_HEX,
    XLSX_MIME_TYPE_HEX
}