import {
  SELECT_PROJECT_CRUD,
  SET_SELECTED_PROJECT_FIELD,
  SET_SELECTED_PROJECT_TYPE,
  SET_PROJECT_VALIDATION_ERRORS,
  CLEAR_PROJECT_VALIDATION_ERRORS,
  CLEAR_PROJECTS,
  FETCH_SOFTWARE_TYPES_SUCCESS,
  FETCH_PROJECT_BUSINESS_COMPONENT_TYPES_SUCCESS,
} from '../constants/actionTypes/project';
import { PROJECT_INITIAL_STATE } from '../components/ServiceManager/Projects/constants';


const projectsReducer = (state = PROJECT_INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_PROJECT_CRUD:
      return {
        ...state,
        selected: action.selectedProject,
      };
    case SET_SELECTED_PROJECT_FIELD:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value },
      };
    case SET_SELECTED_PROJECT_TYPE:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value },
      };
    case CLEAR_PROJECTS:
      return {
        ...state,
        list: []
      }
    case SET_PROJECT_VALIDATION_ERRORS:
      return {
        ...state,
        uploadValidationErrors: action.errors
      }
    case CLEAR_PROJECT_VALIDATION_ERRORS:
      return {
        ...state,
        uploadValidationErrors: []
      }
    case FETCH_SOFTWARE_TYPES_SUCCESS:
      return {
        ...state,
        softwareTypes: action.softwareTypes
      }
    case FETCH_PROJECT_BUSINESS_COMPONENT_TYPES_SUCCESS:
      return {
        ...state,
        businessComponentTypes: action.businessComponentTypes
      }
    default:
      return state;
  }
};

export default projectsReducer;
