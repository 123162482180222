import { PDF_MIME_TYPE_HEX, XLSX_MIME_TYPE_HEX } from '../constants/utils';

const validatePdfMimeType = async (file) => {
  const blob = file.slice(0, 4);
  const hex = await readBlobHexAsync(blob)

  if (!file || hex !== PDF_MIME_TYPE_HEX) {
    return { isValid: false, message: 'Please upload a PDF file.' }
  } else {
    return { isValid: true }
  };
}

const validateExcelMimeType = async (file) => {
  const blob = file.slice(0, 4);
  const hex = await readBlobHexAsync(blob)

  if (!file || hex !== XLSX_MIME_TYPE_HEX) {
    return { isValid: false, message: 'Please upload an Excel file.' }
  } else {
    return { isValid: true }
  };
}

const validateMimeTypeMap = {
  'R&D-Tax-Credits': validatePdfMimeType,
  'R&D-Section-G': validateExcelMimeType
}

function readBlobHexAsync(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      const uint = new Uint8Array(reader.result)
      let bytes = []
      uint.forEach((byte) => {
        bytes.push(byte.toString(16))
      })
      const hex = bytes.join('').toUpperCase()

      resolve(hex);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  })
}
export default validateMimeTypeMap;
