const TOGGLE_CRUD_PANEL = 'TOGGLE_CRUD_PANEL';
const GET_JURISDICTIONS_SUCCESS = 'GET_JURISDICTIONS_SUCCESS';
const GET_HOUR_SOURCES = 'GET_HOUR_SOURCES';
const FETCH_PRINCIPLE_BUSINESS_ACTIVITY_CODES_SUCCESS = 'FETCH_PRINCIPLE_BUSINESS_ACTIVITY_CODES_SUCCESS';

export {
  TOGGLE_CRUD_PANEL,
  GET_JURISDICTIONS_SUCCESS,
  GET_HOUR_SOURCES,
  FETCH_PRINCIPLE_BUSINESS_ACTIVITY_CODES_SUCCESS
}
