import {
  FETCH_ENTITIES_SUCCESS,
  SELECT_ENTITY_FOR_CRUD_SUCCESS,
  GET_CURRENCIES_SUCCESS,
  UPDATE_SELECTED_ENTITY,
  CLEAN_ENTITY,
  SET_ORIGINAL_PARENT
} from '../constants/actionTypes/entity'

const initialState = {
  list: [],
  selectedEntity: {
    name: '',
    jurisdictionName: '',
    principle_business_activity_code: null,
    entity_id: '',
    code: '',
    fiscal_year_end: null,
    currency_id: '',
    tax_jurisdiction_id: '',
    container_id: '',
    year_of_incorporation: '',
    entity_type: '',
    description: '',
    industry: '',
    entity_group_id: '',
    fiscal_year_start: null,
    parentEntities: [],
    jurisdiction: {
      jurisdiction_id: '',
      currency_id: ''
    },
    ein: '',
  },
  countries: [],
}

const entities = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENTITIES_SUCCESS:
      return {
        ...state,
        list: action.entities.map(entity => ({ name: entity.name, jurisdictionName: entity.jurisdiction.name, ...entity }))
      }

    case SELECT_ENTITY_FOR_CRUD_SUCCESS:
      return {
        ...state,
        selectedEntity: { ...action.entity },
      };

    case SET_ORIGINAL_PARENT:
      return {
        ...state,
        selectedEntity: { ...state.selectedEntity, originalParentEntities: action.parentEntities },
      }
    case GET_CURRENCIES_SUCCESS:
      return ({
        ...state,
        currencies: action.currencies,
      })
    case UPDATE_SELECTED_ENTITY:
      return ({
        ...state,
        selectedEntity: { ...state.selectedEntity, [action.key]: action.value, }
      })
    case CLEAN_ENTITY:
      return ({
        ...state,
        selectedEntity: { ...initialState.selectedEntity, parentEntities: [] }
      })

    default: return state;
  }
};



export default entities;
