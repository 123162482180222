import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { getAuthData } from '../selectors/auth';
import { getConfig } from '../selectors/config';


let LDProvider;

const LaunchDarklyFeatureFlagInit = ({ children }) => {
  const [isProviderReady, setConfigurationLoaded] = useState(false);
  const { auth } = useSelector(getAuthData);
  const { XbsEnvironment, launchDarklyEnv } = useSelector(getConfig);

  useEffect(() => {
    if (!isProviderReady && auth && launchDarklyEnv && XbsEnvironment) {
      (async () => {
        LDProvider = await asyncWithLDProvider({
          clientSideID: launchDarklyEnv[XbsEnvironment],
          user: {
            key: auth.id
          },
        });
        setConfigurationLoaded(true);
      })();
    }
  }, [isProviderReady, XbsEnvironment, launchDarklyEnv, auth]);

  return (isProviderReady && LDProvider) ? <LDProvider>{children}</LDProvider> : <>{children}</>;
};

export default LaunchDarklyFeatureFlagInit;
