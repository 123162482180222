import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk';
import getValueFromEvent from '../../../../utils/getValueFromEvent';

// ACTIONS

import { changeReportStage, changePublishedStatus, generateReport, deleteReport, uploadReport } from '../../../../actions/report';


// SELECTORS
import { getReports, getReportTypes, getPublishedVersion } from '../../../../selectors/report';

// COMPONENTS
import FormBuilder from '../../../../commonComponents/FormBuilder'

// CONSTANTS
import { CRUD_TYPES, REPORT_VERSION_ACTIONS, REPORT_VERSION_STATUSES, PUBLISH_REPORT_ACTIONS, STAGES_PUBLISH_ALLOWED } from '../constants';

// UTILS
import buildReportInputFields from './buildReportInputFields'
import { apiClient } from '../../../../api/apiClient';
import downloadFileFromURI from '../../../../utils/downloadFileFromURI';
import { getSelectedEntityObject } from '../../../../selectors/entities';
import { getSelectedContainer } from '../../../../selectors/containers';
import { handleError } from '../../../../actions/error';
import FileUpload from '../../../../commonComponents/FileUpload'

const ReportCrud = (props) => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const reports = useSelector(getReports, shallowEqual);
  const reportTypes = useSelector(getReportTypes, shallowEqual);
  const [newReport, setNewReport] = useState({});
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [error, setError] = useState({});
  const publishedReportVersion = useSelector(getPublishedVersion)
  const renderPublishButton = useMemo(() => STAGES_PUBLISH_ALLOWED.includes(props.stage), [props.stage])
  const isReportGenerated = props.reportVersionStatus === REPORT_VERSION_STATUSES.Generated || props.reportVersionStatus === REPORT_VERSION_STATUSES.Uploaded;
  const entity = useSelector(getSelectedEntityObject, shallowEqual);
  const container = useSelector(getSelectedContainer, shallowEqual);
  const onChange = event => {
    const { name } = event.target;
    const value = getValueFromEvent(event);
    if (name === 'reportId') {
      setNewReport({ ...newReport, reportId: value, reportName: '' })
    } else {
      setNewReport({ ...newReport, [name]: value })
    }
    setError({})
  }

  const handleProjectsChange = (result) => {
    onChange({
      target: {
        name: 'reportProjects',
        value: result
      }
    })
  }

  const handleStageChange = direction => {
    const { id, stage, creditId, entityId } = props
    dispatch(changeReportStage(id, direction, stage, creditId, entityId))
    props.closeCrudPanel();
  }

  const hasErrors = () => {
    const { reportId, reportName, templateId } = newReport
    let newError = {};
    if (!templateId) {
      newError = { ...error, templateId: true }
    }
    if (!reportId && !reportName) {
      newError = { ...error, report: true }
    }
    setError(newError)
    return Object.keys(newError).length
  }

  const constructNewReportBody = () => {
    const selectedReport = reports.find(({ id }) => id === newReport.reportId);
    const selectedTemplate = reportTypes.find(({ id }) => id === newReport.templateId);
    const reportProjectsIds = newReport.reportProjects ? newReport.reportProjects.map(project => project.id) : [];

    return {
      entityId: props.entityId,
      creditId: props.creditId,
      stageId: props.stage,
      templateId: newReport.templateId,
      containerId: container.container_id,
      name: selectedReport ? selectedReport.name : newReport.reportName,
      templateName: selectedTemplate ? selectedTemplate.name : null,
      currencyId: entity.currency_id,
      languageIso: 'en',
      reportId: selectedReport ? selectedReport.id : null,
      reportStage: props.stage,
      reportVersion: selectedReport ? selectedReport.version : null,
      reportVersionId: selectedReport ? selectedReport.versionId : null,
      taxYear: container.tax_year,
      taxJurisdictionCode: entity.jurisdiction.iso_code,
      reportProjects: reportProjectsIds
    };
  }

  const handleGenerateReport = () => {
    if (hasErrors()) {
      return;
    }

    const newReportBody = constructNewReportBody()

    dispatch(generateReport(newReportBody, props.creditId))
    props.closeCrudPanel();
  };

  const handleDownloadReport = async () => {
    try {
      const { id } = props;
      setIsLoadingReport(true);
      const reportFileSignedUrl = await apiClient({
        method: 'get',
        url: `/report/download-by-version-id?reportVersionId=${id}`
      });
      downloadFileFromURI(reportFileSignedUrl);

      setIsLoadingReport(false);
    } catch (error) {
      dispatch(handleError(error))
      setIsLoadingReport(false);
      setError(error);
    }
  };


  const handleDeleteReport = async () => {
    const reportVersionId = props.id;
    const { creditId, stage, entityId } = props;
    setIsLoadingReport(true);
    await dispatch(deleteReport(reportVersionId, stage, creditId, entityId));
    setIsLoadingReport(false);
    props.closeCrudPanel();
  }


  const handlePublishedStatus = actionType => {
    const { id, stage, creditId, entityId } = props
    dispatch(changePublishedStatus(id, actionType, stage, creditId, entityId))
    props.closeCrudPanel()

  }

  const handleCustomFileUpload = async (e) => {

    const [file] = e.target.files;
    const newReportBody = constructNewReportBody()
    dispatch(uploadReport(file, newReportBody))
    props.closeCrudPanel()
  }

  const handleClickPdfUpload = async (e) => {
    if (hasErrors()) {
      e.preventDefault()
      return
    }
  }

  const formActions = [
    {
      name: 'Promote',
      shouldDisplay: props.crudType === CRUD_TYPES.edit,
      onClick: () => handleStageChange(REPORT_VERSION_ACTIONS.promote),
      disabled: props.stage === 4,
      color: 'secondary'
    },
    {
      name: 'Demote',
      shouldDisplay: props.crudType === CRUD_TYPES.edit,
      onClick: () => handleStageChange(REPORT_VERSION_ACTIONS.demote),
      disabled: props.stage === 1,
      color: 'secondary'
    },
    {
      name: 'Delete',
      shouldDisplay: isReportGenerated,
      disabled: isLoadingReport,
      onClick: () => handleDeleteReport(REPORT_VERSION_ACTIONS.demote),
      color: 'secondary'
    },
    {
      name: 'Publish',
      shouldDisplay: renderPublishButton && !props.publishedStatus,
      disabled: Boolean(Object.keys(publishedReportVersion).length),
      onClick: () => handlePublishedStatus(PUBLISH_REPORT_ACTIONS.publish),
      size: 'full',
      helperText: Boolean(Object.keys(publishedReportVersion).length) ? `Please unpublish ${publishedReportVersion.reportName} version ${publishedReportVersion.version} in order to publish ${props.reportName} version ${props.version}` : '',
      color: 'secondary'
    },
    {
      name: 'Unpublish',
      shouldDisplay: Boolean(props.publishedStatus),
      onClick: () => handlePublishedStatus(PUBLISH_REPORT_ACTIONS.unpublish),
      size: 'full',
      color: 'secondary'
    },
    {
      name: 'Generate Report',
      shouldDisplay: props.crudType === CRUD_TYPES.create,
      onClick: handleGenerateReport,
      size: 'full'
    },
    {
      name: 'Download Report',
      shouldDisplay: isReportGenerated,
      disabled: isLoadingReport,
      onClick: handleDownloadReport,
      isLoading: isLoadingReport,
      size: 'full'
    }
  ];

  const buttonToUploadReport = props.crudType === "create" ?
    <div style={{ textAlign: 'center' }}>
      <span >OR</span>
      <FileUpload type="square" onChange={handleCustomFileUpload} onClick={handleClickPdfUpload} buttonText="Upload Report" />
    </div> : null

  return (
    <div>
      <FormBuilder
        fields={props.crudType === CRUD_TYPES.edit ?
          buildReportInputFields({
            ...props,
            reports,
            reportTypes
          }, {
            projects: props.projects || [],
            handleProjectsChange,
            flags,
            containerTaxYear: container.tax_year,
            taxJurisdictionCode: entity.jurisdiction.iso_code
          })
          :
          buildReportInputFields({
            ...newReport,
            reports: [{ name: '-', id: null }, ...reports],
            reportTypes, error,
            crudType: props.crudType
          }, {
            projects: props.projects || [],
            handleProjectsChange,
            flags,
            containerTaxYear: container.tax_year,
            taxJurisdictionCode: entity.jurisdiction.iso_code
          })}
        onChange={onChange}
        formActions={formActions}
      />
      {buttonToUploadReport}
    </div>
  )
}
ReportCrud.propTypes = {
  id: PropTypes.number,
  stage: PropTypes.number,
  creditId: PropTypes.number,
  entityId: PropTypes.number,
  closeCrudPanel: PropTypes.func,
  crudType: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.object)
};

export default ReportCrud
